<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="[
      props.containerClasses,
      { 'mx-auto': props.alignText === 'center' },
      props.width,
      props.maxWidth,
    ]"
    class="headline__container print:!pt-0 print:!pb-2"
  >
    <component
      :is="level"
      :class="styleClasses"
      :style="getHeadlineStyle()"
      class="vdv-headline hyphens-auto max-w-[calc(100vw-var(--thm-spacing-md))]"
      :data-testid="testid"
    >
      <slot v-if="$slots.default" />
      <span v-else :data-testid="`${testid}Span`" v-html="t(headline)"></span>
    </component>

    <div
      v-if="
        hasDecoration &&
        (level === EHeadlineSize.H1 || level === EHeadlineSize.H2)
      "
      class="flex items-center justify-center w-[270px] h-auto mx-auto cursor-default pb-xs"
    >
      <HeadlineDeco
        :stroke-width="props.strokeWidth"
        :logo-color="
          level === EHeadlineSize.H2 ? 'fill-inherit' : props.logoColor
        "
        :stroke-color="strokeColor ?? 'stroke-inherit'"
        :container-class="props.decoContainerClasses"
      />
    </div>
    <div
      v-else-if="hasDecoration && level === EHeadlineSize.H3"
      class="headline__stroke w-[270px] h-[1px] mt-2xs mx-auto mb-2xs !bg-activesection-border-base"
    ></div>
    <p
      v-if="subline"
      class="max-w-screen-lg mx-auto mt-sm"
      :class="styleClasses"
    >
      {{ subline }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { EHeadlineSize } from '@/@types/headline';
import HeadlineDeco from './headline-deco.vue';

export interface Props {
  headline: string;
  subline?: string;
  hasDecoration?: boolean;
  alignText?: string;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | string | EHeadlineSize;
  strokeWidth?: number;
  strokeColor?: string;
  logoColor?: string;
  textColor?: string;
  classes?: string;
  containerClasses?: string;
  decoContainerClasses?: string;
  width?: string;
  testid?: string;
}

const { t } = useTrans();

const props = withDefaults(defineProps<Props>(), {
  headline: '',
  subline: null,
  hasDecoration: false,
  alignText: 'center',
  level: 'h1',
  strokeWidth: 1,
  strokeColor: null,
  logoColor: '#346e73',
  textColor: '',
  classes: '',
  containerClasses: '',
  decoContainerClasses: '',
  width: 'w-fit',
  maxWidth: 'max-w-screen-md',
});

let styleClasses: any[] = [];
let textColorVal = '';

if (props.textColor.startsWith('color:')) {
  styleClasses = [
    {
      [getAlignment()]: true,
      'mx-auto': props.alignText === 'center',
      [props.classes]: props.classes,
    },
  ];
} else {
  setTextColor(props.level);
  styleClasses = [
    {
      [getAlignment()]: true,
      'mx-auto': props.alignText === 'center',
      [textColorVal]: true,
      [props.classes]: props.classes,
    },
  ];
}

function setTextColor(headlineLevel: string) {
  if (props.textColor !== '' && props.textColor != undefined) {
    textColorVal = props.textColor;
  } else {
    if (headlineLevel === 'h1') {
      textColorVal = 'text-vdv-base';
    } else {
      textColorVal = 'text-inherit';
    }
  }
}

function getAlignment() {
  return 'text-' + props.alignText;
}

function getHeadlineStyle() {
  if (props.textColor.startsWith('color:')) {
    return props.textColor;
  }
}
</script>
<style lang="postcss" scoped>
.vdv-headline {
  @apply hyphens-auto;
}

h1,
h2 {
  @apply !font-josefin;
}

h3,
h4,
h5,
h6 {
  @apply !font-text;
}
</style>
