<template>
  <div class="relative z-[1]" :class="containerClass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="269.801"
      height="12.438"
      viewBox="0 0 269.801 12.438"
    >
      <path
        id="Pfad_1027"
        data-name="Pfad 1027"
        d="M-21885.8-22825h120.4s3.367-11.437,14.9-11.437,14.9,11.438,14.9,11.438h119.6"
        transform="translate(21885.801 22836.938)"
        fill="none"
        :class="strokeColor"
        stroke-width="1"
      />
    </svg>
    <div class="absolute w-full cursor-default select-none bot-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38.5 39.2"
        style="height: 8.7px; stroke: none"
        class="mx-auto"
        :fill="props.logoColor"
      >
        <path
          class="cls-1"
          d="M0,0H9.7l9.8,25.6h.2L29.4,0h9.1l-16,39.2H15.6Z"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * @Remark
 * Inline SVGs for the ability to style the stroke/fill-color
 * Vdv-Logo (which the SVG Code is taken from) can be found in the assets folder
 */

const props = defineProps({
  containerClass: {
    type: String,
    required: false,
    default: '',
  },
  width: {
    type: String,
    required: false,
    default: '80',
  },
  strokeWidth: {
    type: Number,
    required: false,
    default: 1,
  },
  strokeColor: {
    type: String,
    required: false,
    default: null,
  },
  logoColor: {
    type: String,
    required: false,
    default: null,
  },
});
</script>

<style scoped>
.v-circle {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(0px - 50px);
  left: calc(50% - 50px);
}
.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
